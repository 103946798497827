import React, { useEffect, useState } from 'react';
import { Row, Col, message } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStockBalances, getMonthlyReport } from '../ducks/actions'
import { exportInvoiceMonthlyReport, exportStockBalance } from '../ducks/services'
import Search from './components/Search'
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { comma } from 'Features/utility';
import { useTranslate } from 'Translate';

export default (props) => {
  const dispatch = useDispatch();
  const stockBalances = useSelector((state) => state.reporting.stockBalances);
  const meta = useSelector((state) => state.eInvoice.monthlyReportOption);
  const monthlyReportData = useSelector((state) => state.eInvoice.monthlyReport)
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const i18n = useTranslate();
  const { t } = i18n;
  const metaStatuses = meta?.statuses?.map(status => ({label: t(`General.${status}`), value: status}))
  const statusOptions = metaStatuses ? [{label: t("General.all_status"), value: ""}, ...metaStatuses] : []
  

  const colName = [
    {
      title: t("Outlet.code"),
      dataIndex: 'location_code',
      key: 'location_code',
      className: 'enable-click',
      sorter: false
    },
    {
      title: t("Outlet.venue"),
      dataIndex: 'location_desc',
      key: 'location_desc',
      className: 'enable-click',
      sorter: false
    },
    {
      title: t("General.date"),
      dataIndex: 'date',
      key: 'date',
      className: 'enable-click',
      sorter: false
    },
    {
      title: t("General.total_count"),
      dataIndex: 'total_count',
      key: 'total_count',
      className: 'enable-click',
      sorter: false,
      align: 'center',
    },
    {
      title: t("General.total_sales_amount"),
      dataIndex: 'amount',
      key: 'amount',
      className: 'enable-click',
      sorter: false,
      align: 'right',
    },
    {
      title: t("General.submitted_count"),
      dataIndex: 'submitted_count',
      key: 'submitted_count',
      className: 'enable-click',
      sorter: false,
      align: 'center',
    },
    {
      title: t("General.submitted_amount"),
      dataIndex: 'submitted_amount',
      key: 'submitted_amount',
      className: 'enable-click',
      sorter: false,
      align: 'right'
    },
    {
      title: t("General.pending_count"),
      dataIndex: 'pending_count',
      key: 'pending_count',
      className: 'enable-click',
      sorter: false,
      align: 'center',
    },
    {
      title: t("General.pending_amount"),
      dataIndex: 'pending_amount',
      key: 'pending_amount',
      className: 'enable-click',
      sorter: false,
      align: 'right'
    },
    {
      title: t("General.status"),
      dataIndex: 'status',
      key: 'status',
      className: 'enable-click',
      sorter: false,
      render: (text) =>
        <div className='flex align-items-center'>
          <span className={text === "pending" ? "c-pending" : "c-success"}>
            {t(`General.${text?.toLowerCase()}`)}
          </span>
        </div>
    },
  ];

  useEffect(() => {
    dispatch(getMonthlyReport(null, props.setLoading))
  }, []);

  useEffect(() => {
    if(meta) {
      if('locations' in meta) {
        const temp = meta.locations.map(location => ({label: `${location.code} ${location.desc}`, value: location.id}))
        setOutlets(temp);
      }
    }
  }, [meta]);

  const exportCsv = async () => {
    if (stockBalances?.total_count === 0) {
      return message.warning(t("General.no_data_export"));
    }
    props.setLoading(true);

    await exportInvoiceMonthlyReport(searchVal).then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const btnList = [
    {
      text: t("General.export_report"),
      classes: 'green-btn attendance-success',
      action: () => exportCsv(),
    },
  ];

  const onSearch = (search) => {
    console.log(search)

    // return
    if (search) {
      let searching = {};

      searching = {
        start_date: search.start_date ? dayjs(search.start_date).startOf('month').format("YYYY-MM-DD") : "",
        end_date: search.end_date ? dayjs(search.end_date).startOf('month').format("YYYY-MM-DD") : "",
        outlet: search?.outlets?.value,
        status: search?.status.value
      };
      setSearchVal(searching);
      dispatch(getMonthlyReport(searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getMonthlyReport(null, props.setLoading));
    }
  };

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <HeadingChip title={t("Menu.monthly_report")} btnList={allowed([AllRoles.INVENTORY_REPORTING.STOCK_BALANCE], 'write') ? btnList : null} />
      </Col>
      {/* refer to csv table layout */}
      <Col span={24} className="inv-report-sb">
        <ListCard
          Search={Search}
          onSearch={onSearch}
          ListData={monthlyReportData?.map(x => ({...x, key: x.id}))}
          ListCol={colName}
          field1={outlets}
          field2={statusOptions}
          pagination={{
            total: monthlyReportData?.length,
            pageSize: 25
          }}
        />
      </Col>
    </Row>
  )
}