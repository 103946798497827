import React, { useState } from 'react';
import Dashboard from '../../templates/Dashboard';
import InvoiceDashboard from 'Modules/Invoice/InvoiceDashboard';
import MonthlyReport from '../../modules/Invoice/MonthlyReport';

const Components = {
  InvoiceDashboard,
  MonthlyReport
};

export default (props) => {
  const InvoiceComp = Components[props.Comp];
  const [loading, setLoading] = useState(false);

  return (
    <Dashboard load={loading} setLoading={setLoading}>
      <InvoiceComp loading={loading} setLoading={setLoading} />
    </Dashboard>
  );
};