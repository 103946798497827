import * as action_types from './constants';

const initialState = {
  monthlyReport: [],
  monthlyReportOption: {}
};

export default (state = initialState, action) => {
  const { type, data, meta } = action;
  switch (type) {
    case action_types.INVOICE_MONTHLY_REPORT:
      return { ...state, monthlyReport: data, monthlyReportOption: meta };
    default:
      return state;
  }
};
