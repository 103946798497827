import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col } from 'antd';
import { InputField, DateField, SelectField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import DateRangeDropdown from 'Modules/Application/components/DateRangeDropdown';
import dayjs from 'dayjs';
import { useTranslate } from 'Translate';
import { isFaFilters } from "../../../../../../configs/constantData";

export default (props) => {
  const { searchVal } = props;
  const { control, handleSubmit, setValue } = useForm();
  const i18n = useTranslate();
  const { t } = i18n;

  const searchStatusLabel = (searchVal?.status === "" || searchVal?.status) ? true : false
  const searchOutletLabel = (searchVal?.outlet === "" || searchVal?.outlet) ? true : false
  const searchOrderTypeLabel = (searchVal?.order_type === "" || searchVal?.order_type) ? true : false
  const searchTenderTypeLabel = (searchVal?.tender_type === "" || searchVal?.tender_type) ? true : false

  useEffect(() => {
    if(searchVal) {
      if (searchVal?.outlet) {
        let outlets = [];
        searchVal?.outlet?.forEach(x => outlets.push({ label: props.field1.find(y => y.value === x)?.label, value: x }))
        setValue("outlet", outlets);
      }
      if (searchVal?.tender_netsuite_synced) {
        setValue("tender_netsuite_synced", props.field6.find(x => x.value === searchVal?.tender_netsuite_synced));
      }
      if (searchVal?.inventory_netsuite_synced) {
        setValue("inventory_netsuite_synced", props.field7.find(x => x.value === searchVal?.inventory_netsuite_synced));
      }
      if (searchVal?.start_date) {
        setValue("start_date", dayjs(searchVal?.start_date));
      }
      if (searchVal?.end_date) {
        setValue("end_date", dayjs(searchVal?.end_date));
      }
      if (searchVal?.status) {
        setValue("status", props.field3.find(x => x.value === searchVal?.status));
      }
      if (searchVal?.is_non_sales) {
        setValue("is_non_sales", props.field4.find(x => x.value === searchVal?.is_non_sales));
      }
    }
  }, [searchVal, props]);

  const onSubmit = (val) => {
    props.onSearch(val);
  }

  const clearProps = () => {
    setValue('reference', '');
    setValue('start_date', '');
    setValue('end_date', '');
    setValue('outlet', '');
    setValue('order_type', '');
    setValue('tender_type', '');
    setValue('is_non_sales', '');
    setValue('status', '');
    setValue('tender_netsuite_synced', '');
    setValue('inventory_netsuite_synced', '');
    props.onSearch({});
  }

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100 inline-form'>
      <Row className='w-100' gutter={[10, 10]}>
        <Col flex="auto">
          <InputField
            fieldname='reference'
            label=''
            class='mb-0 w-100'
            initValue={searchVal?.reference ? searchVal?.reference : ''}
            control={control}
            iProps={{ placeholder: `${t("General.search_bill_no")}..` }}
          />
        </Col>

        <DateRangeDropdown control={control} onSetValue={setValue} placeholderValue={t("Inventory.business_date")} isRequired={false} />

        <Col flex="auto">
          <SelectField
            fieldname='outlet'
            label=''
            class='mb-0 w-100 default-select'
            initValue={searchOutletLabel ? {label: searchVal?.outlet === "" ? t("General.all_outlets") : searchVal?.outlet, value: searchVal?.outlet} : ''}
            control={control}
            iProps={{ placeholder: t("General.outlet"), isMulti: true }}
            selectOption={props.field1}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='is_fa'
            label=''
            class='mb-0 w-100 default-select'
            initValue={isFaFilters[0]}
            control={control}
            selectOption={isFaFilters}
            iProps={{ placeholder: 'Is Fa?' }}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='order_type'
            label=''
            class='mb-0 w-100 default-select'
            initValue={searchOrderTypeLabel ? {label: searchVal?.order_type === "" ? 't("Crm.all_order_types")' : searchVal?.order_type, value: searchVal?.order_type} : ''}
            control={control}
            iProps={{ placeholder: t("Delete.order_type") }}
            selectOption={props.field2}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='tender_type'
            label=''
            class='mb-0 w-100 default-select'
            initValue={searchTenderTypeLabel ? {label: searchVal?.tender_type === "" ? 'All Tender Types' : searchVal?.tender_type, value: searchVal?.tender_type} : ''}
            control={control}
            iProps={{ placeholder: 'Tender Type' }}
            selectOption={props.field5}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='is_non_sales'
            label=''
            class='mb-0 w-100 default-select'
            initValue={props.field4?.[0]}
            control={control}
            selectOption={props.field4}
            iProps={{ placeholder: 'Is Non Sales' }}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='status'
            label=''
            class='mb-0 w-100 default-select'
            initValue={searchStatusLabel ? {label: searchVal?.status === "" ? t("General.all_status") : searchVal?.status, value: searchVal?.status} : ''}
            control={control}
            iProps={{ placeholder: t("General.status") }}
            selectOption={props.field3}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='tender_netsuite_synced'
            label=''
            class='mb-0 w-100 default-select'
            initValue={props.field6?.[0]}
            control={control}
            selectOption={props.field6}
            iProps={{ placeholder: 'Tender Netsuite Synced' }}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='inventory_netsuite_synced'
            label=''
            class='mb-0 w-100 default-select'
            initValue={props.field7?.[0]}
            control={control}
            selectOption={props.field7}
            iProps={{ placeholder: 'COGS Netsuite Synced' }}
          />
        </Col>
        <Col flex="70px">
          <Button className='w-100' danger size='large' type='primary' htmlType='button' onClick={() => clearProps()}>{t("General.clear")}</Button>
        </Col>
        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>{t("General.search")}</Button>
        </Col>
      </Row>
    </Form>
  )
}