import Login from "./Login.json"
import Confirm from "./Confirm.json"
import Crm from "./Crm.json"
import Dashboard from "./Dashboard.json"
import Delete from "./Delete.json"
import Forget from "./Forget.json"
import General from "./General.json"
import Inventory from "./Inventory.json"
import InvReporting from "./InvReporting.json"
import LanguageSwitcher from "./LanguageSwitcher.json"
import Menu from "./Menu.json"
import New from "./New.json"
import Outlet from "./Outlet.json"
import Pos from "./Pos.json"
import Product from "./Product.json"
import SalesReporting from "./SalesReporting.json"
import Success from "./Success.json"
import System from "./System.json"

export default {
  ...Login,
  ...Confirm,
  ...Crm,
  ...Dashboard,
  ...Delete,
  ...Forget,
  ...General,
  ...Inventory,
  ...InvReporting,
  ...LanguageSwitcher,
  ...Menu,
  ...New,
  ...Outlet,
  ...Pos,
  ...Product,
  ...SalesReporting,
  ...Success,
  ...System,
}