import axios from 'Services/axiosInterceptor';
import * as action_types from './constants';
import { externalBaseUrl } from '../../../../configs/constants';


export const getMonthlyReport = (search = {}, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/e-invoice/monthly-report`, {
      // location_ids: search?.outlets,
      location_id_eq: search?.outlet,
      date_from: search?.start_date,
      date_to: search?.end_date,
      status: search?.status
    });
    load && load(false);
    dispatch({
      type: action_types.INVOICE_MONTHLY_REPORT,
      data: data,
      meta: meta
    });
  };
};