import React, { useEffect, useState } from 'react';
import { Row, Col, message, Card, Flex } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import Search from './components/Search'
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { comma } from 'Features/utility';
import { useTranslate } from 'Translate';
import Chart from 'react-google-charts';

const filters = [
  {
    label: "Jan 2025",
    value: "jan-2025",
    
  },
  {
    label: "Feb 2025",
    value: "feb-2025"
  },
]

export default (props) => {
  const [selected, setSelected] = useState(filters[0].value);
  const i18n = useTranslate();
  const { t } = i18n;

  const dropSelection = {
    value: selected,
    onAction: (e) => setSelected(e),
    options: filters
  };

  // Pie chart
  const invoiceData = {
    "jan-2025": {
      chart: [
        ["Invoice", "Invoice Type"],
        ["Inbound", 85],
        ["Outbound", 15],
      ],
      dataName: [
        {value: 200, percentage: 15, name: "In Queue"},
        {value: 800, percentage: 85, name: "Validated"},
        {value: 0, percentage: 0, name: "Awaiting"},
        {value: 0, percentage: 0, name: "Accepted"},
        {value: 0, percentage: 0, name: "Rejected"},
      ]
    },
    "feb-2025": {
      chart: [
        ["Invoice", "Invoice Type"],
        ["Inbound", 20],
        ["Outbound", 80],
      ],
      dataName: [
        {value: 800, percentage: 80, name: "In Queue"},
        {value: 200, percentage: 20, name: "Validated"},
        {value: 0, percentage: 0, name: "Awaiting"},
        {value: 0, percentage: 0, name: "Accepted"},
        {value: 0, percentage: 0, name: "Rejected"},
      ]
    },
  }
  
  const options = {
    pieHole: 0.4,
    is3D: false,
    legend: {
      position: "bottom",
      alignment: "center"
    },
    colors: ["#991b1b", "#ef4444"],
  };


  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <HeadingChip title={t("Menu.e_invoice")} drop={dropSelection} />
      </Col>
      {/* refer to csv table layout */}

      <Col span={24}>
        <Card bordered={false} className={`uni-card`}>
          <Flex justify='space-between' align='center'>
            <Flex vertical gap={"small"}>
              <div className='ag-fontSize24'>Total e-invoices</div>
              <div className='fontSize40 font-bold'>1,000</div>
            </Flex>

            <Chart
              chartType="PieChart"
              width="250px"
              height="250px"
              data={invoiceData[selected].chart}
              options={options}
            />
          </Flex>

          <Flex gap={"10px"} wrap>
          {invoiceData[selected].dataName.map(data => <div className='invoice-dashboard-state' key={data.name}>
            <Flex gap={"5px"} align='center'>
              <div className='fontSize20'>{data.value}</div>
              {data.percentage > 0 && <div className={`${data.percentage >= 80 ? "invoice-80-perc" : "invoice-20-perc"} invoice-perc-box`}>{data.percentage}%</div>}
            </Flex>
            <div>{data.name}</div>
          </div>)}
        </Flex>
        </Card>
      </Col>

      {/* <Col span={24} className="inv-report-sb">
        <ListCard
          isSticky={true}
          scrolling={reportData?.length ? 4000 : 1000}
          Search={Search}
          onSearch={onSearch}
          bordered={true}
          ListData={reportData?.map((x, index) => ({ ...x, key: index }))}
          rowClassName={rowClassName}
          ListCol={colName}
          field1={outlets}
          pagination={false}
          showHeader={reportData?.length ? true : false}
        />
      </Col> */}
    </Row>
  )
}