import AllRoles from './AllRoles';

const titles = {
  Invoice: 'Invoice',
};

export const Invoice = [
  // {
  //   component: 'InvoiceDashboard',
  //   path: '/invoice/invoice-dashboard',
  //   title: titles.Invoice,
  //   key: 'invoice-dashboard',
  //   menu: 'E_Invoice',
  //   submenu: 'Dashboard',
  //   icon: 'OverviewIcon',
  //   parent: true,
  //   parenticon: 'SetupIcon',
  //   permission: [AllRoles.INVENTORY_REPORTING.STOCK_BALANCE],
  // },
  {
    component: 'MonthlyReport',
    path: '/invoice/monthly-report',
    title: titles.Invoice,
    key: 'monthly-report',
    menu: 'E_Invoice',
    submenu: 'Monthly Report',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.INVENTORY_REPORTING.STOCK_BALANCE],
  },
]